import { FC, useEffect, useMemo, useState } from 'react';
import { PaginationState } from '@tanstack/react-table';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import Skeleton from 'react-loading-skeleton';
import { ListItemIcon, MenuItem } from '@mui/material';
import { Edit } from 'react-feather';
import { StyledP, StyledTitleContainer } from '../../../design/theme/shared-style';
import {
  authorizeEditUser,
  authorizeNewNotification,
  authorizeNewUser,
  listAuthorizations, rejectAuthorizationRequest,
} from '../../../api/user/user.api';
import moment from 'moment/moment';
import { EditUserDetailsModal } from './EditUserDetailsModal';
import { NewUserDetailsModal } from './NewUserDetailsModal';
import { NewNotificationDetailsModal } from './NewNotificationDetailsModal';
import { Notifications } from '../../../types/notifications.dto';
import { User } from '../../../types/user.dto';
import { ModalToken } from '../../../components/Shared/ModalToken/ModalToken';
import { toast } from 'react-toastify';
import { AuthorizationStatus } from '../../../types/authorizationRequest.dto';


const AuthorizationsList: FC = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [editUserDetailsModalOpen, setEditUserDetailsModalOpen] = useState<boolean>(false);
  const [newUserDetailsModalOpen, setNewUserDetailsModalOpen] = useState<boolean>(false);
  const [userSelected, setUserSelected] = useState<User[] | null>(null);
  const [newNotificationDetailsModalOpen, setNewNotificationDetailsModalOpen] = useState<boolean>(false);
  const [notificationSelected, setNotificationSelected] = useState<Notifications | null>(null);
  const [requestSelected, setRequestSelected] = useState<any | null>(null);
  const [tokenModalOpen, setTokenModalOpen] = useState<boolean>(false);
  const [successTokenFunction, setSuccessTokenFunction] = useState<(() => void) | null>(null);

  const getList = async () => {
    try {
      setLoading(true);
      const authorizations = await listAuthorizations();
      setTableData(authorizations);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 250,
        hidden: true,
        enableHiding: false,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
      },
      {
        accessorKey: 'status',
        header: 'Estado',
        size: 250,
        hidden: true,
        enableHiding: false,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          // eslint-disable-next-line react/prop-types
          const status = props.cell.getValue<string>();
          if (status === AuthorizationStatus.ACTIVE) {
            return <span>✅ Aceptada</span>;
          } else if (status === AuthorizationStatus.PENDING) {
            return <span>🕐 Pendiente</span>;
          } else if(status === AuthorizationStatus.TEMPORARY_DISABLED) {
            return <span>⌛ Cargando...</span>;
          } else {
            return <span>❌ Rechazada</span>;
          }
        },
      },
      {
        accessorKey: 'type',
        header: 'Tipo',
        size: 250,
        hidden: true,
        enableHiding: false,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          // eslint-disable-next-line react/prop-types
          if (props.cell.getValue<string>() === 'CREATE_USER') {
            return <span>Crear usuario</span>;
            // eslint-disable-next-line react/prop-types
          } else if (props.cell.getValue<string>() === 'SEND_NOTIFICATION') {
            return <span>Enviar notificación</span>;
          } else {
            return <span>Editar usuario</span>;
          }
        },
      },
      {
        accessorKey: 'creator.name',
        header: 'Creador',
        size: 250,
        hidden: true,
        enableHiding: false,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
      },
      {
        accessorKey: 'createdAt',
        header: 'Fecha de creación',
        size: 250,
        hidden: true,
        enableHiding: false,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          // eslint-disable-next-line react/prop-types
          if (props.cell.getValue<string>() && props.cell.getValue<string>() !== 'null') {
            // eslint-disable-next-line react/prop-types
            const formattedDate = moment.utc(props.cell.getValue<string>()).local().format('DD/MM/YYYY');
            return <span>{formattedDate}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
      {
        accessorKey: 'authorizer.name',
        header: 'Autorizador',
        size: 250,
        hidden: true,
        enableHiding: false,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          // eslint-disable-next-line react/prop-types
          return <span>{props.cell.getValue<string>() || '-'}</span>;
        },
      },
      {
        accessorKey: 'authorizationDate',
        header: 'Fecha de autorización',
        size: 250,
        hidden: true,
        enableHiding: false,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnOrdering: false,
        Cell: (props) => {
          // eslint-disable-next-line react/prop-types
          if (props.cell.getValue<string>() && props.cell.getValue<string>() !== 'null') {
            // eslint-disable-next-line react/prop-types
            const formattedDate = moment.utc(props.cell.getValue<string>()).local().format('DD/MM/YYYY');
            return <span>{formattedDate}</span>;
          } else {
            return <span>--</span>;
          }
        },
      },
    ],
    [],
  );

  const openDetailHandler = (requestSelected: any) => {
    setRequestSelected(requestSelected);
    switch (requestSelected.type) {
      case 'EDIT_USER':
        setUserSelected(requestSelected.usersEffected  || null);
        setEditUserDetailsModalOpen(true);
        break;
      case 'CREATE_USER':
        setNewUserDetailsModalOpen(true);
        setUserSelected(requestSelected.usersEffected || null);
        break;
      case 'SEND_NOTIFICATION':
        setNewNotificationDetailsModalOpen(true);
        setNotificationSelected(requestSelected.notificationAffected || null);
        break;
    }
  };

  const editUserApprove = () => {
    setSuccessTokenFunction(() => () => editUserHandler());
    setTokenModalOpen(true);
  };

  const newUserApprove = () => {
    setSuccessTokenFunction(() => () => newUserHandler());
    setTokenModalOpen(true);
  };

  const newNotificationApprove = () => {
    setSuccessTokenFunction(() => () => newNotificationHandler());
    setTokenModalOpen(true);
  };

  const editUserHandler = async () => {
    setTokenModalOpen(false);
    setSuccessTokenFunction(null);
    if (requestSelected) {
      toast.info('Aprobando autorización');
      await authorizeEditUser(requestSelected);
      await getList();
      toast.dismiss();
      toast.success('Se ha aprobado la autorización correctamente.');
    }
  };

  const newUserHandler = async () => {
    setTokenModalOpen(false);
    setSuccessTokenFunction(null);
    if(requestSelected) {
      toast.info('Aprobando autorización');
      const formatObject = {
        ...requestSelected,
        usersEffected: requestSelected.usersEffected.map((user: User) => ({ id: user.id })),
      };
      await authorizeNewUser(formatObject);
      await getList();
      toast.dismiss();
      toast.success('Se ha aprobado la autorización correctamente.');
    }
  };

  const newNotificationHandler = async () => {
    setTokenModalOpen(false);
    setSuccessTokenFunction(null);
    if (requestSelected) {
      toast.info('Aprobando autorización');
      await authorizeNewNotification(requestSelected);
      await getList();
      toast.dismiss();
      toast.success('Se ha aprobado la autorización correctamente.');
    }
  };

  const rejectAuthorization = async () => {
    setTokenModalOpen(false);
    setSuccessTokenFunction(null);
    if (requestSelected) {
      toast.info('Rechazando autorización');
      await rejectAuthorizationRequest(requestSelected);
      await getList();
      toast.dismiss();
      toast.info('Se ha rechazado la autorización correctamente.');
    }
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        {loading ? (
          <Skeleton
            style={{ width: '100%', height: '100px', marginTop: '50px' }}
          />
        ) : tableData && tableData.length > 0 ? (
          <MaterialReactTable
            initialState={{ density: 'compact' }}
            enableStickyHeader
            enableStickyFooter
            columns={columns}
            defaultColumn={{
              minSize: 20,
              maxSize: 160,
              size: 50,
            }}
            data={tableData}
            muiTablePaperProps={{
              elevation: 0,
              sx: {
                borderRadius: '0',
              },
            }}
            enableRowActions={true}
            enableRowSelection={false}
            enableRowOrdering={false}
            enableHiding={false}
            enableFullScreenToggle={false}
            enableMultiRowSelection={false}
            positionActionsColumn="first"
            renderRowActionMenuItems={({ closeMenu, row }) => [
              <MenuItem
                key={0}
                onClick={() => {
                  openDetailHandler(row.original);
                  closeMenu();
                }}
                disabled={
                  row.original.status === AuthorizationStatus.ACTIVE
                  || row.original.status === AuthorizationStatus.REJECTED
                  || row.original.status === AuthorizationStatus.TEMPORARY_DISABLED
                }
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                Revisar
              </MenuItem>,
            ]}
            onPaginationChange={setPagination}
            muiTablePaginationProps={{
              rowsPerPageOptions: [10, 20, 50],
            }}
            state={{
              isLoading: loading,
              pagination,
              columnVisibility: { id: false },
            }}
          />
        ) : (
          <StyledTitleContainer>
            <StyledP>Su listado se encuentra vacío</StyledP>
          </StyledTitleContainer>
        )}
      </div>
      <EditUserDetailsModal
        open={editUserDetailsModalOpen}
        onClose={() => setEditUserDetailsModalOpen(false)}
        userSelected={userSelected}
        newNetSalary={requestSelected?.netSalaryAffected}
        newClabe={requestSelected?.clabeAffected}
        submit={editUserApprove}
        reject={rejectAuthorization}
      ></EditUserDetailsModal>
      <NewUserDetailsModal
        open={newUserDetailsModalOpen}
        onClose={() => setNewUserDetailsModalOpen(false)}
        users={userSelected}
        submit={newUserApprove}
        reject={rejectAuthorization}
      ></NewUserDetailsModal>
      <NewNotificationDetailsModal
        open={newNotificationDetailsModalOpen}
        onClose={() => setNewNotificationDetailsModalOpen(false)}
        notificationSelected={notificationSelected}
        submit={newNotificationApprove}
        reject={rejectAuthorization}
      ></NewNotificationDetailsModal>
      <ModalToken
        open={tokenModalOpen}
        onClose={() => {
          setTokenModalOpen(false);
          setSuccessTokenFunction(null);
        }}
        successToken={successTokenFunction}
      />
    </>
  );
};

export default AuthorizationsList;

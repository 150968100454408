import { UserRoleEnum } from '../../../../types/user-role.enum';
import appLayoutMap from '../../../../utilities/appLayoutMap';
import { getAppLayoutAllowed } from '../../../../api/company/company.api';
import { Profile } from '../../../../types/user.dto';
import { CompanyList } from '../../../AppBar/SideNavBar';
import { User } from '../../../../types/user.dto';
import { listBanks } from '../../../../api/user/user.api';
import { firstLetterUppercase } from '../../../../utilities/i18n/helpers';
import ExcelJS from 'exceljs';
// @ts-ignore
import { saveAs } from 'file-saver';

export type AppLayoutOption = {
  label: string;
  value: string;
};

export type EditFormValues = {
  name: string;
  firstLastname: string;
  secondLastname: string;
  email: string;
  netSalary: number;
  area: string;
  position: string;
  curp: string;
  rfc: string;
  companyEmail: string;
  sex: string;
  bank: number;
  clabe: string;
  birthday: Date | string;
  paymentFrequency: string;
  mobile: string;
  location: string;
  startDate: Date | string | null;
  appLayout: string;
  profile: string | null;
  companyEmployeeNumber: string;
  salaryServiceEnable: boolean;
};

export type CreateFormValues = {
  name: string,
  firstLastname: string,
  secondLastname: string,
  email: string,
  netSalary: string,
  area: string,
  position: string,
  curp: string,
  rfc: string,
  companyEmail: string,
  sex: string,
  bank: string,
  clabe: string,
  birthday: null | Date,
  paymentFrequency: string,
  mobile: string,
  location: string,
  startDate: null | Date,
  appLayout: string,
  profile: string,
  companyEmployeeNumber: string,
  salaryServiceEnable: boolean,
};

export type MappedObjectEmployee = {
  [key: string]: string | number | Date | null | undefined;
  id: string | null;
  secondLastname: string | null;
  firstLastname: string | null;
  curp: string | null;
  email: string | null;
  companyEmail: string | null;
  startDate: string | Date | null;
  twispiEnrollmentDate: Date;
  birthday: string | Date | null;
  name: string | null;
  paymentFrequency: string | null;
  rfc: string | null;
  bank: string | null;
  clabe: string | null;
  netSalary: string | number | null;
  phone: string | null;
  mobile: string | null;
  sex: string | null;
  position: string | null;
  area: string | null;
  companyEmployeeNumber: string | null;
  appLayout: string | null;
  profile: string | null;
  location: string | null;
};

export const loadAppLayoutAllowed = async (loggedUser: User | null) => {
  let appLayoutAllowedNumbers: number[];
  if (loggedUser?.role && loggedUser?.role === UserRoleEnum.ADMIN) {
    appLayoutAllowedNumbers = Object.keys(appLayoutMap).map(key => +key);
  } else {
    appLayoutAllowedNumbers = await getAppLayoutAllowed();
  }
  const loadedAppLayoutOptions: AppLayoutOption[] = appLayoutAllowedNumbers.map(number => ({
    label: appLayoutMap[number.toString()],
    value: number.toString()
  }));
  return loadedAppLayoutOptions;
}

export const hasProfileOrContract = (profileList: Profile[] | null | undefined, companySelectedStorange: CompanyList) => {
  return (profileList && profileList.length > 0) || !!companySelectedStorange.contractData?.activeContract;
}

export const formatSubmit = (values: EditFormValues) => {
  return {
    email: values.email,
    name: values.name,
    firstLastname: values.firstLastname,
    secondLastname: values.secondLastname,
    sex: values.sex,
    bank: values.bank,
    clabe: values.clabe,
    birthday: new Date(values.birthday),
    startDate: values.startDate ? new Date(values.startDate) : null,
    mobile: values.mobile || '',
    curp: values.curp,
    position: values.position || '',
    companyEmail: values.companyEmail,
    area: values.area,
    rfc: values.rfc,
    netSalary: values.netSalary,
    appLayout: values.appLayout,
    profile: values.profile || '',
    location: values.location,
    paymentFrequency: values.paymentFrequency,
    companyEmployeeNumber: values.companyEmployeeNumber,
    salaryServiceEnable: values.salaryServiceEnable
  };
}

export const getBanks = async () => {
  const banksList = await listBanks();
  if (banksList && banksList.length > 0) {
    return banksList;
  }
};

export const emptyNewEmployeeForm = () => {
  return {
    name: '',
    firstLastname: '',
    secondLastname: '',
    email: '',
    netSalary: '',
    area: '',
    position: '',
    curp: '',
    rfc: '',
    companyEmail: '',
    sex: '',
    bank: '',
    clabe: '',
    birthday: null,
    paymentFrequency: '',
    mobile: '',
    location: '',
    startDate: null,
    appLayout: '',
    profile: '',
    companyEmployeeNumber: '',
    salaryServiceEnable: false
  };
};

const getActiveProfile = (userToEdit: User) => {
  if (userToEdit?.profiles && userToEdit.profiles.length > 0) {
    const activeProfile = userToEdit?.profiles?.reduce((prev: any, current: any) => (prev.updatedAt > current.updatedAt) ? prev : current).profile;
    return activeProfile?.id || null;
  } else {
    return null;
  }
};

export const loadEditEmployeeForm = (userToEdit: User, indexOfClabe: number) => {
  return {
    name: userToEdit.name,
    firstLastname: userToEdit.firstLastname,
    secondLastname: userToEdit.secondLastname,
    email: userToEdit.email,
    netSalary: userToEdit.netSalary || 0,
    area: userToEdit.area || '',
    position: userToEdit.position || '',
    curp: userToEdit.curp,
    rfc: userToEdit.rfc,
    companyEmail: userToEdit.companyEmail || '',
    sex: firstLetterUppercase(userToEdit.sex),
    bank: userToEdit?.clabes && userToEdit?.clabes.length > 0
      ? userToEdit.clabes[indexOfClabe || 0]?.bank?.code
      : '',
    clabe: userToEdit?.clabes && userToEdit?.clabes.length > 0 && indexOfClabe !== -1
      ? userToEdit.clabes[indexOfClabe || 0].code
      : '',
    birthday: userToEdit.birthday || '',
    paymentFrequency: userToEdit.paymentFrequency || '',
    mobile: userToEdit.mobile || '',
    location: userToEdit.location || '',
    startDate: userToEdit.startDate || null,
    appLayout: userToEdit.appLayout || '',
    profile: getActiveProfile(userToEdit) || '',
    companyEmployeeNumber: userToEdit.companyEmployeeNumber || '',
    salaryServiceEnable: userToEdit.salaryServiceEnable
  };
};

export const resetFormEditEmployee = () => {
  return {
    name: '',
    firstLastname: '',
    secondLastname: '',
    email: '',
    netSalary: 0,
    area: '',
    position: '',
    curp: '',
    rfc: '',
    companyEmail: '',
    sex: '',
    bank: 0,
    clabe: '',
    birthday: '',
    paymentFrequency: '',
    mobile: '',
    location: '',
    startDate: null,
    appLayout: '',
    profile: null,
    companyEmployeeNumber: '',
    salaryServiceEnable: false,
  }
};

export const formatMobileChange = (value: string) => {
  const filteredValue = value.replace(/[^0-9+]/g, '');
  return filteredValue.slice(0, 16);
};

const bankAndClabeValidation = (item: any) => {
  if ((item['Codigo banco'] || item['Código banco']) && !item['CLABE']) {
    item['Codigo banco'] = null;
  } else if (!(item['Codigo banco'] || item['Código banco']) && item['CLABE']) {
    item['CLABE'] = null;
  }
  return item;
};

function ExcelDateToJSDate(serial: number) {
  const utcDate = new Date(Math.round((serial - 25569) * 86400 * 1000));
  utcDate.setUTCHours(utcDate.getUTCHours() + 3);
  const year = utcDate.getUTCFullYear();
  const month = ('0' + (utcDate.getUTCMonth() + 1)).slice(-2);
  const day = ('0' + utcDate.getUTCDate()).slice(-2);
  const hours = ('0' + utcDate.getUTCHours()).slice(-2);
  const minutes = ('0' + utcDate.getUTCMinutes()).slice(-2);

  if (hours !== '00' || minutes !== '00') {
    return `${year}-${month}-${day}T${hours}:${minutes}Z`;
  } else {
    return `${year}-${month}-${day}`;
  }
}

const parseDate = (dateValue: any): Date | string => {
  if (!isNaN(dateValue)) {
    return ExcelDateToJSDate(dateValue);
  }
  if (typeof dateValue === 'string') {
    const parts = dateValue.split('/');
    if (parts.length === 3) {
      let year = parseInt(parts[2], 10);
      let month = parseInt(parts[1], 10) - 1;
      let day = parseInt(parts[0], 10);
      if (month > 11) {
        month = parseInt(parts[0], 10) - 1;
        day = parseInt(parts[1], 10);
      } else if (day > 31) {
        year = parseInt(parts[0], 10);
        day = parseInt(parts[2], 10);
      }
      return new Date(year, month, day);
    }
  }
  return '';
};

function normalizeString(str: string): string {
  if (typeof str === 'number') {
    str = (str as number).toString();
  } else if (typeof str !== 'string') {
    return str;
  }
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\n/g, "")
    .replace(/\s+/g, " ")
    .toLowerCase()
    .trim();
}

function findKeyByValue(value: string, map: { [key: string]: string }): string | undefined {
  const normalizedValue = normalizeString(value);
  return Object.keys(map).find(key => normalizeString(map[key]) === normalizedValue);
}

const getFieldValue = (item: any, possibleKeys: string[]): any => {
  for (const key of possibleKeys) {
    if (item[key] !== undefined) {
      return item[key];
    }
  }
  return null;
}

export const mapXmlPropertiesToDTO = (items: any) => {
  return items.map((item: any) => {
    let normalizedItem: any = {};
    Object.keys(item).forEach((key) => {
      const normalizedKey = normalizeString(key);
      normalizedItem[normalizedKey] = item[key];
    });
    normalizedItem = bankAndClabeValidation(normalizedItem);
    const mappedObject: MappedObjectEmployee = {
      id: normalizedItem['id twispi'],
      secondLastname: normalizedItem['apellido materno']?.trim(),
      firstLastname: normalizedItem['apellido paterno']?.trim(),
      curp: typeof normalizedItem['curp'] === 'string' ? normalizedItem['curp'].trim() : normalizedItem['curp']?.toString()?.trim(),
      email: normalizedItem['email']?.trim(),
      companyEmail: normalizedItem['email empresarial']?.trim(),
      startDate: getFieldValue(normalizedItem, [
        'fecha de ingreso',
        'fecha de ingreso(dd/mm/aaaa)',
      ])
        ? parseDate(getFieldValue(normalizedItem, [
          'fecha de ingreso',
          'fecha de ingreso(dd/mm/aaaa)',
        ]))
        : '',
      twispiEnrollmentDate: new Date(),
      birthday: getFieldValue(normalizedItem, [
        'fecha de nacimiento',
        'fecha de nacimiento(dd/mm/aaaa)',
      ])
        ? parseDate(getFieldValue(normalizedItem, [
          'fecha de nacimiento',
          'fecha de nacimiento(dd/mm/aaaa)',
        ]))
        : '',
      name: normalizedItem['nombre(s)']?.trim(),
      paymentFrequency: normalizedItem['periodicidad']?.trim(),
      rfc: typeof normalizedItem['rfc'] === 'string' ? normalizedItem['rfc']?.trim() : normalizedItem['rfc']?.toString().trim(),
      bank: normalizedItem['codigo banco'],
      clabe: normalizedItem['clabe'],
      netSalary: (normalizedItem['sueldo neto'] && normalizedItem['sueldo neto'] > 0)
        ? normalizedItem['sueldo neto']
        : (normalizedItem['sueldo neto por periodo'] && normalizedItem['sueldo neto por periodo'] > 0)
          ? normalizedItem['sueldo neto por periodo']
          : '0',
      phone: normalizedItem['telefono'],
      mobile: normalizedItem['telefono'],
      sex: normalizedItem['sexo']?.trim(),
      position: normalizedItem['puesto']?.trim(),
      area: normalizedItem['area']?.trim(),
      companyEmployeeNumber: typeof normalizedItem['id de empleado'] === 'string' ? normalizedItem['id de empleado']?.trim() : normalizedItem['id de empleado']?.toString().trim(),
      appLayout: normalizedItem['applayout'],
      profile: normalizedItem['perfil']
        ? normalizedItem['perfil']?.trim()
        : normalizedItem['perfil twispi']?.trim(),
      location: normalizedItem['ubicacion']?.trim(),
    };
    if(mappedObject.appLayout) {
      mappedObject.appLayout = findKeyByValue(mappedObject.appLayout, appLayoutMap) || mappedObject.appLayout;
    }
    if(typeof mappedObject.netSalary === 'string') {
      if(mappedObject.netSalary[0] === '$'){
        mappedObject.netSalary = mappedObject.netSalary.slice(1);
      }
      mappedObject.netSalary = parseInt(mappedObject.netSalary);
    }
    for (const key in mappedObject) {
      if(mappedObject[key] && typeof mappedObject[key] === 'string') {
        mappedObject[key] = (mappedObject[key] as string).trim();
      }
      if (mappedObject[key] === '--' || mappedObject[key] === '') {
        mappedObject[key] = null;
      }
    }
    return mappedObject;
  });
};

export const generateExcelTemplate = async (companySelected: any) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Template');
  const hasContract = companySelected && companySelected.contractData && companySelected.contractData.activeContract;

  const data = [
    ['Nombre(s)', 'Apellido Paterno', 'Apellido Materno', 'Fecha de Nacimiento' + String.fromCharCode(10) + '(dd/mm/aaaa)', 'RFC', 'CURP', 'Sexo', 'Email', 'Teléfono', 'ID de Empleado',
      'Puesto', 'Área', 'Ubicación', 'Fecha de Ingreso' + String.fromCharCode(10) + '(dd/mm/aaaa)', 'Email empresarial', 'Sueldo neto por periodo', 'Periodicidad', 'CLABE', 'Código banco'],
    ['Obligatorio', 'Obligatorio', 'Obligatorio', 'Obligatorio', 'Obligatorio', 'Obligatorio', 'Obligatorio', 'Obligatorio', 'Opcional', 'Opcional',
      'Obligatorio', 'Opcional', 'Opcional', 'Opcional', 'Opcional', 'Opcional', 'Opcional', 'Opcional', 'Opcional', 'Obligatorio'],
  ];

  if (hasContract) {
    data[0].push('Perfil Twispi');
  } else {
    data[0].push('AppLayout');
  }

  data.forEach((row, rowIndex) => {
    const newRow = worksheet.addRow(row);
    newRow.height = 20;
    newRow.eachCell((cell) => {
      cell.font = {
        color: { argb: rowIndex === 0 ? 'FFFFFF' : '000000' },
        bold: true,
      };
    });

    if (rowIndex === 0) {
      newRow.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
      newRow.height = 30;
      newRow.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4287F5' },
        };
      });
    } else if (rowIndex === 1) {
      newRow.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: cell.value === 'Obligatorio' ? 'E6B8B7' : 'FFFF99' },
        };
      });
    }
  });
  worksheet.getCell('G3').dataValidation = {
    type: 'list',
    allowBlank: true,
    formulae: ['"Masculino,Femenino"'],
    promptTitle: 'Seleccione',
    prompt: 'Por favor seleccione un valor de la lista',
    errorTitle: 'Valor inválido',
    error: 'El valor que ha ingresado no es válido',
  };
  worksheet.getCell('Q3').dataValidation = {
    type: 'list',
    allowBlank: true,
    formulae: ['"Semanal, Mensual, Catorcenal, Quincenal"'],
    promptTitle: 'Seleccione',
    prompt: 'Por favor seleccione un valor de la lista',
    errorTitle: 'Valor inválido',
    error: 'El valor que ha ingresado no es válido',
  };
  if (hasContract && companySelected.contractData.relatedProfiles) {
    const arrayWithProfilesName = companySelected.contractData.relatedProfiles.map((profile: Profile) => profile.name);
    const profileOptions = arrayWithProfilesName.join(',');
    worksheet.getCell('T3').dataValidation = {
      type: 'list',
      allowBlank: true,
      formulae: [`"${profileOptions}"`],
      promptTitle: 'Seleccione',
      prompt: 'Por favor seleccione un valor de la lista',
      errorTitle: 'Valor inválido',
      error: 'El valor que ha ingresado no es válido',
    };
  } else {
    worksheet.getCell('T3').dataValidation = {
      type: 'list',
      allowBlank: true,
      formulae: ['"1, 2, 3, 4, 5, 6"'],
      promptTitle: 'Seleccione',
      prompt: 'Por favor seleccione un valor de la lista',
      errorTitle: 'Valor inválido',
      error: 'El valor que ha ingresado no es válido',
    };
  }
  worksheet.columns = Array(23).fill({ width: 20 });
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'template.xlsx');
};

export type HandleImportOptions = {
  confirmMessage: string;
  loadingMessage: string;
  successMessage?: (count: number) => string;
  noChangesMessage?: string;
  errorMessage?: string;
  processItems: (items: any[]) => Promise<number | void>;
  afterProcess?: () => void;
};

export function calculateObjectSizeInBytes(obj: any): number {
  const jsonString = JSON.stringify(obj);
  const encoder = new TextEncoder();
  const byteArray = encoder.encode(jsonString);
  return byteArray.length;
}